import React, { useRef, useContext } from 'react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import * as styles from './gallery.module.scss'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import SiteContext from '../site-context/site-context'

const GalleryImage = ({ image, key }) => {
  const {lightbox: { lightboxImageSrc, setLightboxImageSrc }} = useContext(SiteContext)
  const imageUrl = imageUrlFor(buildImageObj(image)).width(1200).fit('fill').url()

  return (
    <button className={styles.galleryImage} data-open-lightbox-image={key} onClick={() => setLightboxImageSrc(imageUrl)}>
      <img className={styles.basicImage} src={imageUrl} alt={image.alt} />
      <span>
        <svg width='58' height='58' viewBox='0 0 58 58' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M18.8311 26.0054H33.1788' stroke='black' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/>
          <path d='M26.0059 18.8315V33.1793' stroke='black' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/>
          <path d='M26.0053 44.8361C36.4056 44.8361 44.8368 36.4049 44.8368 26.0046C44.8368 15.6042 36.4056 7.1731 26.0053 7.1731C15.605 7.1731 7.17383 15.6042 7.17383 26.0046C7.17383 36.4049 15.605 44.8361 26.0053 44.8361Z' stroke='black' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/>
          <path d='M39.3203 39.321L50.2158 50.2165' stroke='black' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'/>
        </svg>
      </span>
    </button>
  )
}

const Gallery = ({ node }) => {
  const { images, slider, _key } = node
  if (!images || images.length === 0) { return }

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  return (
    <div className={styles.gallery}>
      {
        !slider &&
          <div className={styles.grid}>
            {
              images.map((image, index) => {
                return (
                  <GalleryImage image={image} key={`gallery-image-basic--${_key}-${index}`} />
                )
              })
            }
          </div>
      }

      {
        slider &&
          <div className={styles.sliderHolder}>
            <Swiper
              modules={[Navigation]}
              slidesPerView='auto'
              loop={false}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current
              }}
              className={styles.slider}
            >
              {
                images.map((image, index) => {
                  return (
                    <SwiperSlide key={`gallery-image-slide--${_key}-${index}`} className={styles.slide}>
                      <GalleryImage image={image} key={`gallery-image--${_key}-${index}`}/>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>

            <button ref={navigationPrevRef} className={styles.prevBtn} aria-label='Previous Slide'>
              <svg width='29' height='30' viewBox='0 0 29 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M0.585785 13.5858C-0.195264 14.3668 -0.195264 15.6332 0.585784 16.4142L13.3137 29.1421C14.0948 29.9232 15.3611 29.9232 16.1421 29.1421C16.9232 28.3611 16.9232 27.0948 16.1421 26.3137L4.82843 15L16.1421 3.68629C16.9232 2.90524 16.9232 1.63891 16.1421 0.857863C15.3611 0.0768146 14.0948 0.0768145 13.3137 0.857863L0.585785 13.5858ZM29 13L2 13L2 17L29 17L29 13Z' fill='black'/>
              </svg>
            </button>

            <button ref={navigationNextRef} className={styles.nextBtn} aria-label='Next Slide'>
              <svg width='29' height='30' viewBox='0 0 29 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M28.4142 16.4142C29.1953 15.6332 29.1953 14.3668 28.4142 13.5858L15.6863 0.857864C14.9052 0.0768158 13.6389 0.0768158 12.8579 0.857864C12.0768 1.63891 12.0768 2.90524 12.8579 3.68629L24.1716 15L12.8579 26.3137C12.0768 27.0948 12.0768 28.3611 12.8579 29.1421C13.6389 29.9232 14.9052 29.9232 15.6863 29.1421L28.4142 16.4142ZM0 17H27V13H0V17Z' fill='black'/>
              </svg>
            </button>
          </div>
      }
    </div>
  )
}

export default Gallery
