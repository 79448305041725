// extracted by mini-css-extract-plugin
export var hide = "block-content-module--hide--WfOZP";
export var allHide = "block-content-module--all--hide--hMiv7";
export var smallHide = "block-content-module--small--hide--FwJAy";
export var mediumHide = "block-content-module--medium--hide--b5Jvq";
export var mediumDownHide = "block-content-module--medium-down--hide--RV-QG";
export var mediumUpHide = "block-content-module--medium-up--hide--KSzth";
export var largeHide = "block-content-module--large--hide--R9jby";
export var xlargeHide = "block-content-module--xlarge--hide--TFzmC";
export var xxlargeHide = "block-content-module--xxlarge--hide--h6fJ3";
export var blockContent = "block-content-module--blockContent--p050S";
export var basicImage = "block-content-module--basicImage--aJeLo";
export var codeBlock = "block-content-module--codeBlock--bDMtv";
export var iframeHolder = "block-content-module--iframeHolder--MNHC+";
export var videoHolder = "block-content-module--videoHolder--h2GWa";
export var quote = "block-content-module--quote--TaeWb";
export var zigzagHolder = "block-content-module--zigzagHolder--k9uYv";