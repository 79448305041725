// extracted by mini-css-extract-plugin
export var hide = "zigzag-module--hide--XeVM7";
export var allHide = "zigzag-module--all--hide--BbIcm";
export var smallHide = "zigzag-module--small--hide--8+VoA";
export var mediumHide = "zigzag-module--medium--hide--PJM2b";
export var mediumDownHide = "zigzag-module--medium-down--hide--zEfj1";
export var mediumUpHide = "zigzag-module--medium-up--hide--GTnwn";
export var largeHide = "zigzag-module--large--hide--ALPCO";
export var xlargeHide = "zigzag-module--xlarge--hide--C8Q8F";
export var xxlargeHide = "zigzag-module--xxlarge--hide--35p++";
export var zigzag = "zigzag-module--zigzag--5TYGM";
export var slide = "zigzag-module--slide--dWBiI";