import React from 'react'
import * as styles from './zigzag.module.scss'

const ZigZag = (props) => {
  return (
    <div className={styles.zigzag}>
      <svg id="zigzag" data-name="zigzag" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.34 17.32">
        <polygon points="288.33 0 278.08 10.25 267.83 0 257.58 10.25 247.33 0 237.08 10.25 226.83 0 216.62 10.21 206.41 0 196.16 10.25 185.91 0 175.66 10.25 165.41 0 155.3 10.12 145.18 0 134.93 10.25 124.68 0 114.43 10.25 104.18 0 93.93 10.25 83.68 0 73.47 10.21 63.27 0 53.02 10.25 42.77 0 32.52 10.25 22.27 0 12.02 10.25 3.54 1.77 0 5.3 12.02 17.32 22.27 7.07 32.52 17.32 42.77 7.07 53.02 17.32 63.27 7.07 73.52 17.32 83.72 7.11 93.93 17.32 104.18 7.07 114.43 17.32 124.68 7.07 134.93 17.32 145.05 7.21 155.16 17.32 165.41 7.07 175.66 17.32 185.91 7.07 196.16 17.32 206.41 7.07 216.66 17.32 226.87 7.11 237.08 17.32 247.33 7.07 257.58 17.32 267.83 7.07 278.08 17.32 288.33 7.07 296.81 15.55 300.35 12.02 288.33 0" className="zigzag-line" />
      </svg>
    </div>
  )
}

export default ZigZag