import React, { useEffect } from 'react'
import clientConfig from '../../../client-config'
import BasePortableText from '@sanity/block-content-to-react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import * as styles from './block-content.module.scss'
import ZigZag from '../zigzag/zigzag'
import Gallery from '../gallery/gallery'
import hljs from 'highlight.js'

const StandardImage = ({ node }) => {
  if (!node) { return }
  return (
    <img className={styles.basicImage} src={imageUrlFor(buildImageObj(node)).width(1200).fit('fill').url()} alt={node?.alt} style={{ maxWidth: `${node?.maxWidth}px` }} />
  )
}

const Iframe = ({ node }) => {
  if (!node) { return }
  return (
    <div className={styles.iframeHolder} dangerouslySetInnerHTML={{__html: node.codeSnippet}} />
  )
}

const Video = ({ node }) => {
  if (!node) { return }
  return (
    <div className={styles.videoHolder}>
      <video
        controls={node.showControls}
        muted={!node.showControls}
        autoplay={!node.showControls}
        aria-label={node.title}
        poster={node.poster ? imageUrlFor(buildImageObj(node.poster)).width(1200).fit('fill').url() : false}
      >
        {
          node.video &&
            <source src={node.video} type='video/mp4' />
        }
      </video>
    </div>
  )
}

const Quote = ({ node }) => {
  if (!node) { return }
  return (
    <div className={styles.quote}>
      <blockquote>
        <span>{node.quote}</span>

        {
          node.author &&
            <span>— {node.author}</span>
        }
      </blockquote>
    </div>
  )
}

const CodeBlock = ({ node }) => {
  if (!node) { return }

  useEffect(() => {
    document.querySelectorAll('[data-style-code]').forEach((code) => {
      if (code.classList.contains('hljs')) { return }
      hljs.highlightElement(code)
    })
  }, [])

  return (
    <div className={styles.codeBlock}>
      <pre><code data-style-code>{node.code}</code></pre>
    </div>
  )
}

const serializers = {
  types: {
    basicImage: StandardImage,
    gallery: Gallery,
    quote: Quote,
    iframe: Iframe,
    video: Video,
    codeBlock: CodeBlock
  }
}

const BlockContent = ({ blocks, inPage }) => {
  return (
    <div>
      { inPage &&
        <div className={styles.zigzagHolder}>
          <ZigZag />
        </div>
      }

      <BasePortableText className={inPage ? styles.blockContent : '' } blocks={blocks} serializers={serializers} {...clientConfig.sanity} />
    </div>
  )
}

export default BlockContent
